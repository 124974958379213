import "./App.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Features from "./components/Features";
import Gallery from "./components/Gallery";
//import Testimonials from "./components/Testimonials";
import CTA from "./components/CTA";
import Footer from "./components/Footer";
import "@fortawesome/fontawesome-free/css/all.min.css";
import styles from "./style";

function App() {
  return (
    <div className="leading-normal tracking-normal text-white gradient w-full overflow-hidden">

        <Navbar />

      <Hero />
      <Features />
      <Gallery />
      <CTA />
      <Footer />
    </div>
  );
}

export default App;
